<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <div>
            <feather-icon
              icon="ClockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Aguardando Autorização</span>
          </div>
        </template>
        <authorizations-list />
      </b-tab>

      <b-tab>
        <template #title>
          <div>
            <feather-icon
              icon="CheckIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Autorizados</span>
          </div>
        </template>
        <authorizations-report />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AuthorizationsList from './authorizations-list/AuthorizationsList.vue'
import AuthorizationsReport from './authorizations-report/AuthorizationsReport.vue'

export default {
  components: {
    BTabs,
    BTab,
    AuthorizationsList,
    AuthorizationsReport,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
    }
  },
}
</script>
