<template>
  <b-sidebar
    id="authorizations-reject"
    :visible="isAuthorizationsRejectActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-authorizations-reject-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ "Motivo da rejeição" }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="reason"
            rules="required"
          >
            <b-form-group
              label="Razão da rejeição"
              label-for="reason"
            >
              <b-form-input
                id="reason"
                v-model="authorizationsData.reason"
                autofocus
                :state="validationContext.touched ? getValidationState(validationContext) : null"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Voltar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, watch } from '@vue/composition-api'
import {
  required, alphaNum,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import formError from '@core/comp-functions/forms/form-error'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAuthorizationsRejectActive',
    event: 'update:is-authorizations-reject-active',
  },
  props: {
    isAuthorizationsRejectActive: {
      type: Boolean,
      required: true,
    },
    authorizationsId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const blankAuthorizationsData = {
      reason: '',
    }

    const authorizationsData = ref(JSON.parse(JSON.stringify(blankAuthorizationsData)))
    const resetAuthorizationsData = () => {
      authorizationsData.value = JSON.parse(JSON.stringify(blankAuthorizationsData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAuthorizationsData)

    function onSubmit() {
      const authorizations = { ...authorizationsData.value, id: props.authorizationsId }
      store.dispatch('app-authorizations/rejectAuthorizations', authorizations)
        .then(() => {
          emit('refetch-data')
          emit('update:is-authorizations-reject-active', false)
        })
        .catch(error => {
          const { setErrors, getErrorMessage } = formError(refFormObserver.value, error)
          setErrors()
          if (getErrorMessage()) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro',
                icon: 'XOctagonIcon',
                variant: 'danger',
                text: getErrorMessage(),
              },
            })
          }
        })
    }

    watch(() => props.isAuthorizationsRejectActive, () => {
      resetForm()
      resetAuthorizationsData()
    })

    return {
      authorizationsData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#authorizations-reject {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
