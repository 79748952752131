import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAuthorizations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician-duty/pendences', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAuthorizationsReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician-duty/authorized-report', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveAuthorizations(ctx, authorizationsData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/physician-duty/${authorizationsData.id}/authorize`, {})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    rejectAuthorizations(ctx, authorizationsData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/physician-duty/${authorizationsData.id}/unauthorize`, authorizationsData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    authorizedReportExport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician-duty/authorized-report-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
