import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  format, parseISO,
} from 'date-fns'

export default function useAuthorizationsList() {
  // Use toast
  const toast = useToast()

  const refAuthorizationsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'physician.name',
      label: 'Médico',
      sortable: true,
      sortKey: 'physicianName',
    },
    {
      key: 'workplace',
      label: 'Unidade',
      sortable: true,
      sortKey: 'workplaceName',
    },
    { key: 'amount_paid', label: 'Valor pago' },
    {
      key: 'start',
      label: 'Início',
      sortable: true,
      sortKey: 'startDate',
    },
    {
      key: 'end',
      label: 'Fim',
      sortable: true,
      sortKey: 'endDate',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      sortKey: 'authorizations',
    },
    { key: 'actions', label: 'Ações' },
  ]
  const perPage = ref(10)
  const totalAuthorizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('workplaceName')
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAuthorizationsListTable.value ? refAuthorizationsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAuthorizations.value,
    }
  })

  const refetchData = () => {
    refAuthorizationsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter], () => {
    refetchData()
  })

  const fetchAuthorizations = (ctx, callback) => {
    store
      .dispatch('app-authorizations/fetchAuthorizations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        situation: situationFilter.value,
        status: statusFilter.value,
        sortBy: sortBy.value,
        sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { data: authorizationss } = response.data
        const numberFormat = value => new Intl.NumberFormat('id').format(value)
        const formatDate = value => format(parseISO(value), 'dd/MM/yyyy HH:mm')

        const authorizationsList = authorizationss.map(authorizations => {
          const p = {
            ...authorizations,
            amount_paid: `R$ ${numberFormat(authorizations.amount_paid / 100)}`,
            type: `${authorizations.workplace_duty.duty_type} - ${authorizations.workplace_duty.duration}h`,
            start: formatDate(authorizations.start_date),
            end: formatDate(authorizations.end_date),

          }
          return p
        })
        callback(authorizationsList)
        totalAuthorizations.value = response.data.meta.pagination.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching authorizations',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAuthorizations,
    tableColumns,
    perPage,
    currentPage,
    totalAuthorizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAuthorizationsListTable,

    refetchData,

    // Extra Filters
    situationFilter,
    statusFilter,
  }
}
