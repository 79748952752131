<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row align-h="start">
          <b-col
            cols="12"
            md="2"
          >
            <label for="begin-date">Início em</label>
            <b-form-datepicker
              id="begin-date"
              v-model="startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              @input="refetchData"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <label for="end-date">Fim em</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="pt-BR"
              @input="refetchData"
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <label for="workplace-id">Unidades</label>
            <v-select
              id="workplace-id"
              v-model="workplaceId"
              name="workplace-id"
              :options="workplaceOptions"
              label="label"
              item-value="value"
              item-text="label"
              @input="refetchData"
            >
              <span slot="no-options">Nenhum local encontrado.</span>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex w-100">
              <div class="flex-grow-1">
                <label for="physician-user-id">Médicos</label>
                <v-select
                  id="physician-user-id"
                  v-model="physicianUserId"
                  name="physician-user-id"
                  :options="physicianUserOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  @input="refetchData"
                >
                  <span slot="no-options">Nenhum médico encontrado.</span>
                </v-select>
              </div>
              <div class="pt-2 ml-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="downloadReport"
                >
                  Exportar
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAuthorizationsListTable"
        class="position-relative"
        :items="fetchAuthorizations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        :small="true"
      >
        <template #cell(physician.name)="{ item }">
          <div>{{ item.physician.name }}</div>
          <small class="text-muted">
            {{ formatCpf(item.physician.cpf, true) }}
          </small>
        </template>

        <template #cell(start)="{ item }">
          {{ item.start }} - {{ item.end }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BTable, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCpf } from '@core/utils/utils'
import vSelect from 'vue-select'
import useAuthorizationsList from './useAuthorizationsList'
import authorizationsStoreModule from '../authorizationsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BTable,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const AUTHORIZATIONS_APP_STORE_MODULE_NAME = 'app-authorizations'

    // Register module
    if (!store.hasModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)) store.registerModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME, authorizationsStoreModule)

    const isAuthorizationsRejectSidebarActive = ref(false)
    const currentyAuthorizationsId = ref(null)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)
    })

    const physicianUserOptions = ref([])
    const workplaceOptions = ref([])

    store.dispatch('app-authorizations/fetchPhysicians', { perPage: 99999999 })
      .then(response => {
        physicianUserOptions.value = response.data.data.map((physician, index) => ({
          index,
          value: physician.user_id,
          label: `${physician.name} - ${formatCpf(physician.cpf, true)}`,
        }))
      })

    store.dispatch('app-authorizations/fetchWorkplaces', { perPage: 99999999 })
      .then(response => {
        workplaceOptions.value = response.data.data.map(work => ({
          value: work.id,
          label: `${work.name}`,
        }))
      })

    const {
      fetchAuthorizations,
      tableColumns,
      perPage,
      currentPage,
      totalAuthorizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuthorizationsListTable,
      refetchData,
      // UI
      resolveAuthorizationsStatusVariant,
      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      startDate,
      endDate,
      physicianUserId,
      workplaceId,
      downloadReport,
    } = useAuthorizationsList()

    return {
      isAuthorizationsRejectSidebarActive,
      fetchAuthorizations,
      tableColumns,
      perPage,
      currentPage,
      totalAuthorizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuthorizationsListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveAuthorizationsStatusVariant,

      currentyAuthorizationsId,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,
      startDate,
      endDate,
      physicianUserId,
      workplaceId,
      physicianUserOptions,
      workplaceOptions,
      downloadReport,
      formatCpf,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
