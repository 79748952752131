export default function formError(refFormObserver, error) {
  const getErrors = errorParam => {
    const errors = errorParam?.response?.data?.errors
    return errors ? Object.keys(errors).reduce((messages, key) => ({ ...messages, [key]: errors[key][0] }), {}) : {}
  }

  const getErrorMessage = () => error?.response?.data?.message

  const setErrors = () => {
    const errors = getErrors(error)
    refFormObserver.setErrors(errors)
  }

  return {
    setErrors,
    getErrorMessage,
  }
}
