import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  endOfDay,
  format,
  parseISO,
  subDays,
} from 'date-fns'
import { formatDate } from '@core/utils/utils'

export default function useAuthorizationsList() {
  // Use toast
  const toast = useToast()

  const refAuthorizationsListTable = ref(null)
  const tableColumns = [
    {
      key: 'physician.name',
      label: 'Médico',
      sortable: true,
      sortKey: 'physicianName',
    },
    {
      key: 'workplace',
      label: 'Unidade',
      sortable: true,
      sortKey: 'workplaceName',
    },
    { key: 'amount_paid', label: 'Valor pago' },
    {
      key: 'start',
      label: 'Início/Fim',
      sortable: true,
      sortKey: 'startDate',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      sortKey: 'authorizations',
    },
    {
      key: 'authorized_by_name',
      label: 'Autorizado Por',
      sortable: false,
      sortKey: 'authorizations',
    },
    {
      key: 'authorized_at',
      label: 'Autorizado Em',
      sortable: false,
      sortKey: 'authorizations',
    },
  ]
  const perPage = ref(10)
  const totalAuthorizations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('workplaceName')
  const isSortDirDesc = ref(true)
  const situationFilter = ref(null)
  const statusFilter = ref(null)
  const startDate = ref(format(subDays(new Date(), 7), 'yyyy-MM-dd'))
  const endDate = ref(format(endOfDay(new Date()), 'yyyy-MM-dd'))
  const physicianUserId = ref('')
  const workplaceId = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refAuthorizationsListTable.value ? refAuthorizationsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAuthorizations.value,
    }
  })

  const refetchData = () => {
    refAuthorizationsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, situationFilter, statusFilter, startDate, endDate], () => {
    refetchData()
  })

  const getReportParams = () => ({
    q: searchQuery.value,
    perPage: perPage.value,
    page: currentPage.value,
    situation: situationFilter.value,
    status: statusFilter.value,
    sortBy: sortBy.value,
    sortDirection: isSortDirDesc.value ? 'desc' : 'asc',
    start_date: startDate.value,
    end_date: endDate.value,
    workplace: workplaceId.value ? workplaceId.value.value : '',
    user: physicianUserId.value ? physicianUserId.value.value : '',
  })

  const fetchAuthorizations = (ctx, callback) => {
    store
      .dispatch('app-authorizations/fetchAuthorizationsReport', getReportParams())
      .then(response => {
        const { data: authorizationss } = response.data
        const numberFormat = value => new Intl.NumberFormat('id').format(value)
        const formatDt = (value, f) => format(parseISO(value), f)

        const authorizationsList = authorizationss.map(authorizations => {
          const p = {
            ...authorizations,
            amount_paid: `R$ ${numberFormat(authorizations.amount_paid / 100)}`,
            type: `${authorizations.workplace_duty.duty_type} - ${authorizations.workplace_duty.duration}h`,
            start: formatDt(authorizations.start_date, 'dd/MM/yy HH:mm'),
            end: formatDt(authorizations.end_date, 'HH:mm'),
            authorized_at: formatDt(authorizations.authorized_at, 'dd/MM/yy HH:mm'),
          }
          return p
        })
        callback(authorizationsList)
      })
      .catch(error => {
        const errors = Object.values((error.response && error.response.status === 422 && error.response.data.data) || {}).map(a => a[0])
        toast({
          component: ToastificationContent,
          props: {
            title: errors.join('. '),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadReport = () => {
    const params = getReportParams()
    store.dispatch('app-authorizations/authorizedReportExport', params)
      .then(response => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        const start = formatDate(parseISO(startDate.value))
        const end = formatDate(parseISO(endDate.value))
        link.download = `ListaPlantoesAutorizados_${start}_a_${end}.xlsx`
        link.click()
        URL.revokeObjectURL(link.href)
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAuthorizations,
    tableColumns,
    perPage,
    currentPage,
    totalAuthorizations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAuthorizationsListTable,

    refetchData,
    startDate,
    endDate,

    // Extra Filters
    situationFilter,
    statusFilter,
    physicianUserId,
    workplaceId,
    downloadReport,
  }
}
