<template>

  <div>
    <authorizations-reject
      :is-authorizations-reject-active.sync="isAuthorizationsRejectSidebarActive"
      :authorizations-id="currentyAuthorizationsId"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row align-h="end">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entradas</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
                debounce="500"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAuthorizationsListTable"
        class="position-relative"
        :items="fetchAuthorizations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro correspondente encontrado"
        :sort-desc.sync="isSortDirDesc"
        :small="true"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="approveDuty(data.item)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">Aceitar</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="rejectDuty(data.item)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">Recusar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAuthorizations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination,
  BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthorizationsReject from './AuthorizationsReject.vue'
import useAuthorizationsList from './useAuthorizationsList'
import authorizationsStoreModule from '../authorizationsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    AuthorizationsReject,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const AUTHORIZATIONS_APP_STORE_MODULE_NAME = 'app-authorizations'

    // Register module
    if (!store.hasModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)) store.registerModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME, authorizationsStoreModule)

    const isAuthorizationsRejectSidebarActive = ref(false)
    const currentyAuthorizationsId = ref(null)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTHORIZATIONS_APP_STORE_MODULE_NAME)
    })

    const {
      fetchAuthorizations,
      tableColumns,
      perPage,
      currentPage,
      totalAuthorizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuthorizationsListTable,
      refetchData,

      // UI
      resolveAuthorizationsStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,

    } = useAuthorizationsList()

    function approveDuty(authorization) {
      store.dispatch('app-authorizations/approveAuthorizations', authorization)
        .then(() => {
          refetchData()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erro',
              icon: 'XOctagonIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    }

    function rejectDuty(authorizations) {
      currentyAuthorizationsId.value = authorizations.id
      isAuthorizationsRejectSidebarActive.value = true
    }

    return {

      isAuthorizationsRejectSidebarActive,

      fetchAuthorizations,
      tableColumns,
      perPage,
      currentPage,
      totalAuthorizations,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAuthorizationsListTable,
      refetchData,
      approveDuty,

      // Filter
      avatarText,

      // UI
      resolveAuthorizationsStatusVariant,

      currentyAuthorizationsId,

      // Extra Filters
      roleFilter,
      planFilter,
      situationFilter,
      statusFilter,

      rejectDuty,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
